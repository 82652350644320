<template>
<div class="modal-card" style="width: auto">
  <header class="modal-card-head">
    <p class="modal-card-title">{{dateFormatted}}</p>
  </header>
  <section class="modal-card-body">
    <b-field>
      <b-checkbox
      v-model="holiday"
      @input="onChangeHoliday"
      :true-value="true">Marcar como Festivo</b-checkbox>
    </b-field>
    <div class="notification is-danger"  v-if="holiday">
        <p style="text-align: center">ESTE DÍA SE HA MARCADO COMO FESTIVO</p>
    </div>
    <b-table
    style=""
    :data="events"
    striped
    bordered
    height="100%"
    narrowed
    sticky-header
    focusable
    :selected.sync="selected"
    @dblclick="onSelectSchedule">
    <b-table-column label="Horario" v-slot="props">
      <a @click="() => onClick(props.row.total, props.row.date_time_formatted)" >{{props.row.date_time_formatted}}</a>
    </b-table-column>
      <b-table-column label="" v-slot="props">
        <div :style="`width:40px; height: 40px; background-color: ${props.row.color}`"></div>
      </b-table-column>
      <b-table-column label="Tipo" v-slot="props">
        {{props.row.patient_type_formatted}}
      </b-table-column>
      <b-table-column label="Nombre" v-slot="props">
      {{props.row.full_name}}
      </b-table-column>

      <b-table-column label="Folio" v-slot="props">
        {{props.row.folio}}
      </b-table-column>
      <b-table-column label="Cita médica" v-slot="props">
        {{props.row.medical_date}}
      </b-table-column>
      <template slot="empty">
        <section class="section">
          <div class="content has-text-grey has-text-centered">
            <p>
              <b-icon
                  icon="box-open"
                  size="is-large">
              </b-icon>
            </p>
            <p>No hay citas por mostrar.</p>
          </div>
        </section>
      </template>
    </b-table>
  </section>
</div>
</template>

<script>
export default {
  name: "ListSchedule",
  props: {
    events: [],
    date_time: Date
  },
  data(){
    return {
      selected: {},
      holiday: false
    }
  },
  computed: {
    dateFormatted() {
      let days= ['Domingo ','Lunes ','Martes ','Miércoles ','Jueves ','Viernes ','Sábado ','Domingo '];
      let months=['Enero ', 'Febrero ', 'Marzo ','Abril ', 'Mayo ','Junio ','Julio ','Agosto ','Septiembre ','Octubre ','Noviembre ','Diciembre '];
      return  days[this.date_time.getDay().toString()]+ this.date_time.getDate().toString().padStart(2, '0') + ' de ' + months[this.date_time.getMonth().toString()] + ' de ' + this.date_time.getFullYear();
    },
    transformDate(){
      const newDate= `${this.date_time.getFullYear()}-${(this.date_time.getMonth()+1).toString().padStart(2, '0')}-${this.date_time.getDate().toString().padStart(2, '0')}`
      return newDate;
    }
  },
  mounted() {

  },
  watch: {
    events: function(newValue, oldValue){
      if(newValue && Array.isArray(newValue) && newValue.length) {
        console.log(newValue.map(item=>item.id), newValue.length)
       /*
        this.holiday = newValue.reduce((acc, item) => {
          console.log(newValue, item.id, item.is_holiday)
          return item.is_holiday || acc;
        })
        */
        let result = false;
        newValue.forEach(item => {
          result = result || item.is_holiday
        })
        this.holiday = !!result;
      }else{
        this.holiday = false;
      }
    }
  },
  methods: {
    async onSelectSchedule(event){
      this.$emit('select', event);

    },
    onClick(total, date_time) {
      this.$toast.info(`El total de citas a las ${date_time} es de ${total}` )
    },
    async onChangeHoliday(){
      if(this.holiday){
        const data = {
          date_time: this.date_time,
          is_holiday: true,
          patient_type: "festivo",
          folio: '00',
          full_name: 'No agendar',
          medical_date: this.date_time,
        }
        this.$buefy.dialog.alert('Se marcó el día como festivo');
        //mandar al back un evento con holiday true
        console.log(this.holiday,this.date_time, this.transformDate);
        this.$http.post('/schedules/',data).then( (data) => {
          this.$emit('update');
        }).catch(errors => {
          this.$alfalab.showError(errors);
        }).finally(() => {

        });
      }else{
        console.log('eliminando')
        const holidayEvents = this.events.filter(item => {
          return !!item.is_holiday
        })
        if(holidayEvents){
          //pedir al backend que lo borre
          this.$loader.show();
          await holidayEvents.forEach(async item => {
           await this.$http.delete(`/schedules/${item.id}`);
          })
          this.$loader.hide();
          this.$emit('update');
        }
      }
    }
  }
 }
</script>

<style scoped>

</style>
