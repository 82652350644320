<template>
  <div class="modal-card" style="width: auto">
    <header class="modal-card-head">
      <p class="modal-card-title">Citas agendadas</p>
      <button
          type="button"
          class="delete"
          @click="$parent.close()"/>
    </header>
    <section class="modal-card-body">
      <b-table
      style=""
      :data="search_results"
      striped
      bordered
      height="100%"
      narrowed
      sticky-header
      focusable
      :selected.sync="selected"
      @dblclick="onSelectSchedule">
        <b-table-column label="Nombre" v-slot="props">
          {{props.row.full_name}}
        </b-table-column>
        <b-table-column label="Tipo" v-slot="props">
          {{props.row.patient_type_formatted}}
        </b-table-column>
        <b-table-column label="Folio" v-slot="props">
          {{props.row.folio}}
        </b-table-column>
        <b-table-column label="Cita" v-slot="props">
          {{props.row.medical_date}}
        </b-table-column>
        <template slot="empty">
          <section class="section">
            <div class="content has-text-grey has-text-centered">
              <p>
                <b-icon
                    icon="box-open"
                    size="is-large">
                </b-icon>
              </p>
              <p>No hay citas seleccionadas.</p>
            </div>
          </section>
        </template>
      </b-table>
    </section>
    <footer class="modal-card-foot">
      <b-button native-type="button" type="is-primary"  @click="$parent.close()">Aceptar</b-button>
    </footer>
  </div>
</template>

<script>
export default {
  name: "FilterSchedule",
  props: {
    search_results: Array
  },
  data(){
    return {
      selected: {}
    }
  },
  methods: {
    async onSelectSchedule(event){
      this.$emit('select', event);

    }
  }
}
</script>

<style scoped>

</style>
