<template>
<div class="modal-card" style="width: auto; height:500px">
  <header class="modal-card-head">
    <p class="modal-card-title">Agendar cita {{dateFormatted}}</p>
    <button
        type="button"
        class="delete"
        @click="$parent.close()"/>
  </header>
<section class="modal-card-body">
  <div class="columns is-12 is-multiline">
    <div class="column is-4">
      <b-field label="Horario estudios">
        <b-timepicker
            placeholder="Click to select"
            icon="clock"
            :incrementMinutes="minutesGranularity"
            :incrementHours="hoursGranularity"
            :min-time="minTime"
            :max-time="maxTime"
            v-model="time"
        />
      </b-field>
    </div>
    <div class="column is-4">
      <b-field label="Fecha de cita medica"
               label-position="on-border">
        <b-datepicker v-model="medical_date"
                        :max-date="maxDatetime"
                        :min-date="minDatetime"/>
      </b-field>
    </div>
    <div class="column is-4">
      <b-field label="Tipo paciente"
               label-position="on-border">
        <b-select v-model="patient_type"
                  placeholder="Seleccionar tipo">
          <option value="adulto">Adulto</option>
          <option value="oncologico">Oncologico</option>
          <option value="ginecologica">Ginecologica</option>
          <option value="pediatrico">Pediatricos ( 0-11 años )Muestras sanguíneas no tamices</option>
          <option value="tamiz">Tamiz</option>
          <option value="sincita">Pacientes sin cita</option>
          <option value="prioritario">Pacientes con prioridad por fecha de cita medica</option>
          <option value="sinayuno">Ajuste de horario por otros estudios que no requieren ayuno</option>
          <option value="ajustehorario">Ajuste de horario por otros estudios</option>
          <option value="medicamentos">Toma de medicamentos</option>
        </b-select>
      </b-field>
    </div>
    <div class="column is-6">
      <b-field label="No.Solicitud"
               label-position="on-border">
        <b-input v-model="folio"/>
      </b-field>
    </div>
    <div class="column is-6">
      <b-field label="Nombre completo"
               label-position="on-border">
        <b-input v-model="full_name"/>
      </b-field>
    </div>
  </div>

  <b-field label="Observaciones"
           label-position="on-border">
    <b-input v-model="observations" type="textarea"/>
  </b-field>
</section>
  <footer class="modal-card-foot">
    <b-button native-type="button" type="is-primary"  @click="onSave">Aceptar</b-button>
    <b-button native-type="button" type=""  @click="$parent.close()">Cancelar</b-button>
  </footer>
</div>
</template>

<script>
export default {
  name: "DataSchedule",
  props: {
    date_time: Date,
  },
  data(){
    const min = new Date()
    min.setHours(5)
    min.setMinutes(0)
    const max = new Date()
    max.setHours(19)
    max.setMinutes(0)
    const maxYear = new Date()
    maxYear.setFullYear(maxYear.getFullYear() + 3,0,0)
    const minYear = new Date()
    minYear.setFullYear(min.getFullYear()-1,0,1)
    return {
      patient_type: '',
      folio: '',
      full_name: '',
      //medical_date: this.date_time,
      medical_date: new Date(),
      observations: '',
      minutesGranularity: 15,
      hoursGranularity: 1,
      minTime: min,
      maxTime: max,
      time: new Date(),
      maxDatetime: maxYear,
      minDatetime: minYear
    }
  },
  computed: {
    transformDate(){
 //     const newDate = `${this.date_time.getFullYear()}-${(this.date_time.getMonth()+1).toString().padStart(2, '0')}-${this.date_time.getDate().toString().padStart(2, '0')} ${this.date_time.getHours().toString().padStart(2, '0')}:${this.date_time.getMinutes().toString().padStart(2, '0')}:00`;
      const newDate= `${this.date_time.getFullYear()}-${(this.date_time.getMonth()+1).toString().padStart(2, '0')}-${this.date_time.getDate().toString().padStart(2, '0')}`
      return newDate;
    },
    transformTime(){
      const newTime = `${this.time.getHours().toString().padStart(2, '0')}:${this.time.getMinutes().toString().padStart(2, '0')}:00`;
      return newTime;
    },
    dateFormatted() {
      let days= ['Domingo ','Lunes ','Martes ','Miércoles ','Jueves ','Viernes ','Sábado ','Domingo '];
      let months=['Enero ', 'Febrero ', 'Marzo ','Abril ', 'Mayo ','Junio ','Julio ','Agosto ','Septiembre ','Octubre ','Noviembre ','Diciembre '];
      return  days[this.date_time.getDay().toString()]+ this.date_time.getDate().toString().padStart(2, '0') + ' de ' + months[this.date_time.getMonth().toString()] + ' de ' + this.date_time.getFullYear();
    }
  },
  mounted() {
    const copiedDate = new Date(this.time.getTime());
    copiedDate.setMinutes(0);
    copiedDate.setHours(6);
    this.time = copiedDate;
  },
  methods: {
    onSave(){
       this.$loader.show();
       const data = {
         patient_type: this.patient_type,
         folio: this.folio,
         full_name: this.full_name,
         medical_date: this.medical_date,
         observations: this.observations,
         date_time: this.transformDate + ' ' + this.transformTime,
       }
       this.$http.post('/schedules/', data).then(({data}) => {
          this.$toast.success('Cita agregada exitosamente');
          this.$parent.close();
          this.$emit('update');
       }).catch(errors => {
          this.$alfalab.showError(errors);
          this.$loader.hide();
       }).finally(() => {
         //this.$loader.hide();
       });
    }
  }
}
</script>

<style scoped>

</style>
