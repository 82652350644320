<template>
  <div>
    <div class="section-title">
      <h3>AGENDAR CITA</h3>
    </div>
    <div class="column is-12 has-background-white is-multiline">
      <div style="display: flex; flex-wrap: wrap; gap: 16px; padding: 16px;">
        <div class="column is-2">
          <b-field label="Año"
                   label-position="on-border">
            <b-select placeholder="2022" v-model="year" @input="onHandleYearChange">
              <option :value="year.value" :key="year.value" v-for="year in yearsRange">{{year.text}}</option>
            </b-select>
          </b-field>
        </div>
        <div class="column is-2">
          <b-field label="Mes"
                   label-position="on-border">
            <b-select placeholder="Enero" v-model="search_month" @input="onHandleMonthChange">
              <option :key="month.code" v-for="month in months" :value="month.code">{{month.name}}</option>
            </b-select>
          </b-field>
        </div>
        <div class="column is-4">
          <b-field label="Nombre"
                   label-position="on-border">
            <b-input v-model="search_name" @keypress.native.enter="onSearch"
            />
          </b-field>
        </div>
        <div class="column is-2">
          <b-field label="No.solicitud"
                   label-position="on-border">
            <b-input v-model="search_folio"/>
          </b-field>
        </div>
        <div class="column is-1">
          <b-button icon-left="search" type="is-primary" @click="onSearch"/>
        </div>
      </div>
      <div style="display: flex; flex-wrap: wrap; gap: 1px; padding: 0 16px 16px 16px; justify-content: space-between">
        <div style="display: flex; flex-wrap: wrap; gap: 1px;">
          <b-button icon-left="arrow-left" type="is-primary" @click="onPrevious"/>
          <b-button icon-left="arrow-right" type="is-primary" @click="onNext"/>
          <b-button type="is-primary" label="Hoy" @click="onToday"/>
        </div>

        <p>{{date_time | month}} {{date_time.getFullYear()}}</p>
        <div style="display: flex; flex-wrap: wrap; gap: 1px;">
          <b-button type="is-primary" label="Mes" @click="onViewMonth"/>
          <b-button type="is-primary" icon-left="add" label="Agregar" @click="onAdd" v-if="typeView === 'day'"/>
        </div>
      </div>
      <FullCalendar ref="fullCalendar" :options="calendarOptions" :style="`display: ${typeView === 'calendar' ? 'block' : 'none'}`" />
      <list-schedule v-if="typeView === 'day'" :date_time="date_time" :events="events_day" @select="onSelectEvent" @update="onUpdate"/>
    </div>
    <b-modal v-model="showModal"
             width="80vw">
      <data-schedule :date_time="date_time" @update="onUpdate"/>
    </b-modal>
    <b-modal v-model="showFilter">
      <filter-schedule :search_results="search_results" @select="onSelectSchedule"/>
    </b-modal>
    <b-modal v-model="showDetails" >
      <details-schedule :event="event" @update="onUpdate" @edit="onEdit"/>
    </b-modal>
    <b-modal v-model="showEdit">
      <edit-schedule :event="event" @update="onUpdate"/>
    </b-modal>
  </div>
</template>

<script>

import '@fullcalendar/core/vdom' // solves problem with Vite
import FullCalendar from '@fullcalendar/vue'
import dayGridPlugin from '@fullcalendar/daygrid'
import interactionPlugin from '@fullcalendar/interaction'
import esLocale from '@fullcalendar/core/locales/es'
import timeGridPlugin from '@fullcalendar/timegrid'
import DataSchedule from "@/components/schedule/DataSchedule";
import FilterSchedule from "@/components/schedule/FilterSchedule";
import listPlugin from '@fullcalendar/list';
import DetailsSchedule from "@/components/schedule/DetailsSchedule";
import EditSchedule from "@/components/schedule/EditSchedule";
import {mapGetters} from 'vuex';
import ListSchedule from "@/components/schedule/ListSchedule";

export default {
  name: "Index",
  components: {
    FullCalendar, // make the <FullCalendar> tag available
    DataSchedule,
    FilterSchedule,
    DetailsSchedule,
    EditSchedule,
    ListSchedule
  },
  data() {
    return {
      typeView: 'calendar',
      events: [],
      event: {},
      date_time: new Date(),
      showModal: false,
      showFilter: false,
      showDetails: false,
      showEdit: false,
      search_results: [],
      search_folio: '',
      search_name: '',
      year: ((new Date()).getFullYear()).toString(),
      events_day: [],
      search_month: ((new Date()).getMonth()+1).toString().padStart(2,'0'),
      calendarOptions: {
        customButtons: {
          agregar: {
            text: 'Agregar',
            click: (event) => {this.handleDateClick(event, true)},
          },
        },
        plugins: [dayGridPlugin, interactionPlugin, timeGridPlugin,listPlugin],
        initialView: 'dayGridMonth',
        timeZone: 'America/Mexico_City',
        locale: 'localeEs',
        slotDuration: '00:15:00',
        dayMaxEventRows: 1,
        selectable: true,
        events: [],
        headerToolbar: {
          left: '',
          center: '',
          right: ''
          // right: 'dayGridMonth,timeGridWeek,timeGridDay'
        },
        //acceso a edición de días del mes
        /* dayCellContent: {
          html: `<button>texto</button>`
        }
      */
        dateClick: this.handleDateClick,
        eventClick:  this.handleEventClick,
      }
    }
  },
  computed: {
    ...mapGetters(['months']),
    yearsRange() {
      const date = new Date();
      const years = [{value: date.getFullYear() + 1, text: date.getFullYear() +1}];
      this.$alfalab.yearsRange(3, true).forEach(item => {
        years.push(item);
      })
      return years;
    },
  },
  mounted() {
    this.wrapper = document.getElementById('main-wrapper');
    this.wrapper.classList.add('is-expanded');
    const params = {
      month: ((new Date()).getMonth() + 1).toString().padStart(2, '0'),
      year: (new Date()).getFullYear(),
    }
    this.prepareData(params);
    //this.setEventListenerCalendar();
  },
  methods: {
    onToday() {
      this.$refs.fullCalendar.getApi().today();
      this.date_time = this.$refs.fullCalendar.getApi().getDate();
      const date = this.$refs.fullCalendar.getApi().getDate();
      if(this.typeView === 'calendar'){
        const params = {
          month: (date.getMonth() + 1).toString().padStart(2, '0'),
          year: (date).getFullYear(),
        }
        this.prepareData(params);
      }else{
        this.handleDateClick();
      }
    },
    onPrevious(){
      this.$refs.fullCalendar.getApi().incrementDate(this.setSlotDuration(-1))
      //this.$refs.fullCalendar.getApi().prev();
      this.date_time = this.$refs.fullCalendar.getApi().getDate();
      const date = this.$refs.fullCalendar.getApi().getDate();
      if(this.typeView === 'calendar') {
        const params = {
          month: (date.getMonth() + 1).toString().padStart(2, '0'),
          year: (date).getFullYear(),
        }
        this.prepareData(params);
      }else{
        this.handleDateClick();
      }
    },

    onNext(){
      this.$refs.fullCalendar.getApi().incrementDate(this.setSlotDuration())
      this.date_time = this.$refs.fullCalendar.getApi().getDate();
      const date = this.$refs.fullCalendar.getApi().getDate();
      if(this.typeView === 'calendar') {
        const params = {
          month: (date.getMonth() + 1).toString().padStart(2, '0'),
          year: (date).getFullYear(),
        }
        this.prepareData(params);
      }else{
        this.handleDateClick();
      }
    },
    onViewMonth(){
      this.typeView = 'calendar';
      const date = this.$refs.fullCalendar.getApi().getDate();
      const params = {
        month: (date.getMonth() + 1).toString().padStart(2, '0'),
        year: (date).getFullYear(),
      }
      this.prepareData(params);
    },
    setEventListenerCalendar(){
      document.querySelector('.fc-today-button').addEventListener('click', () => {
        const date = this.$refs.fullCalendar.getApi().getDate();
        this.prepareData({
          month: (date.getMonth() + 1).toString().padStart(2, '0'),
          year: date.getFullYear(),
        })
      })
      document.querySelector('.fc-prev-button').addEventListener('click', () => {
        const date = this.$refs.fullCalendar.getApi().getDate();
        this.prepareData({
          month: (date.getMonth() + 1).toString().padStart(2, '0'),
          year: date.getFullYear(),
        })
      })
      document.querySelector('.fc-next-button').addEventListener('click', () => {
        const date = this.$refs.fullCalendar.getApi().getDate();
        this.prepareData({
          month: (date.getMonth() + 1).toString().padStart(2, '0'),
          year: date.getFullYear(),
        })
      })

      document.querySelector('.fc-dayGridMonth-button').addEventListener('click', () => {
        document.querySelector('.fc-agregar-button').style.visibility = 'hidden';
      })

      document.querySelector('.fc-listDay-button ').addEventListener('click', () => {
        document.querySelector('.fc-agregar-button').style.visibility = 'visible';
      })
    },
    clearEvents() {
      const events = this.$refs.fullCalendar.getApi().getEvents();
      events.forEach(item => {
        item.remove();
      })
    },
    setEvents() {
      this.events.forEach(item => {
        const events = this.$refs.fullCalendar.getApi().addEvent({
          title: item.title,
          date:  item.date_time,
          backgroundColor: item.color,
          extendedProps:{
            event: item
          }
        })
      })
    },
    prepareData(params) {
      this.$loader.show();
      this.$http.get('/schedules', {
        params: Object.assign({}, params, {type: 'month'})
      }).then(({data}) => {
        this.events = data.data;
        this.clearEvents();
        this.setEvents();
      }).catch(errors => {
        this.$alfalab.showError(errors);
      }).finally(() => {
        this.$loader.hide();
      });
    },
    onAdd(){
      this.showModal = true;
      this.date_time = this.$refs.fullCalendar.getApi().getDate();
    },
    handleDateClick(event, showModal = false) {
      this.typeView = 'day';
      this.$loader.show();
      let date = this.$refs.fullCalendar.getApi().getDate();
      if(event){
        date = new Date();
        const parts = event.dateStr.split('-');
        date.setFullYear(parts[0], parts[1]-1, parts[2]);
        date.setHours(0);
        date.setMinutes(0);
        this.$refs.fullCalendar.getApi().gotoDate(date);
      }
      date.setHours(0);
      date.setMinutes(0);
      this.date_time = date;
      const params = {
        date, type: 'day'
      }
      this.$http.get('/schedules', {
        params
      }).then(({data}) => {
          this.events_day = data.data;
      }).catch(errors => {
        this.$alfalab.showError(errors);
      }).finally(() => {
        this.$loader.hide();
      })

      //this.date_time = this.$refs.fullCalendar.getApi().getDate();
      //   this.calendarOptions.initialView= 'timeGridDay'
      //   this.calendarOptions= Object.assign({},this.calendarOptions,{initialView: 'timeGridDay'})
      /*
      this.$refs.fullCalendar.getApi().changeView('listDay', event.date);
      document.querySelector('.fc-agregar-button').style.visibility = 'visible';
      if (showModal) {
        this.showModal = true;

      }
      */
    },
    setSlotDuration(factor = 1){
      let duration =  {};
      if(this.typeView === 'calendar'){
        duration = {
          'month': (factor)
        };
      }else{
        duration =  {
          'day': (factor)
        }
      }
      return duration;
    },
    handleEventClick(event){
      /*
      this.showDetails =true;
      this.event = event.event.extendedProps.event;
       */
    },
    onUpdate(date_time){
      /*
      if(date_time){
        this.prepareData({
          month: (date_time.getMonth() + 1).toString().padStart(2, '0'),
          year: (date_time).getFullYear(),
        })
      }else{
        this.prepareData({
          month: (this.date_time.getMonth() + 1).toString().padStart(2, '0'),
          year: (this.date_time).getFullYear(),
        })
      }
       */
      if(this.typeView === 'calendar'){
        const date = this.$refs.fullCalendar.getApi().getDate();
        const params = {
          month: (date.getMonth() + 1).toString().padStart(2, '0'),
          year: (date).getFullYear(),
        }
        this.prepareData(params);
      }else{
        this.handleDateClick();
      }
    },
    onEdit(){
      this.showEdit=true;
    },
    onSearch() {
      this.$loader.show();
      this.$http.get(`/schedules/`,{
        params: {
          year: this.year, month: this.search_month, name: this.search_name, folio: this.search_folio
        }
      }).then(({data}) => {
        this.search_results = data.data;
        this.showFilter = true;
      }).catch(errors => {
        this.$alfalab.showError(errors);
      }).finally(() => {
        this.$loader.hide();
      });
    },
    onSelectSchedule(event) {
      this.event = event;
      this.showDetails = true;
      this.showFilter = false;
    },
    onSelectEvent(event) {
      this.event = event;
      this.showDetails = true;
    },
    onHandleYearChange(value) {
      console.log(value);
      const date = new Date(value, 0, 1);
      this.$refs.fullCalendar.getApi().gotoDate(date);
      this.date_time = date;
      const params = {
        month: (date.getMonth() + 1).toString().padStart(2, '0'),
        year: (date).getFullYear(),
      }
      this.prepareData(params);
    },
    onHandleMonthChange(value) {
      console.log(value);
      const date = new Date(this.year,value,0);
      this.$refs.fullCalendar.getApi().gotoDate(date);
      this.date_time = date;
      const params = {
        month: (date.getMonth() + 1).toString().padStart(2, '0'),
        year: (date).getFullYear(),
      }
      this.prepareData(params);
    },
  }
}
</script>

<style scoped>
h2 {
  margin: 0;
  font-size: 16px;
}

ul {
  margin: 0;
  padding: 0 0 0 1.5em;
}

li {
  margin: 1.5em 0;
  padding: 0;
}

b { /* used for event dates/times */
  margin-right: 3px;
}

.demo-app {
  display: flex;
  min-height: 100%;
  font-family: Arial, Helvetica Neue, Helvetica, sans-serif;
  font-size: 14px;
}

.demo-app-sidebar {
  width: 300px;
  line-height: 1.5;
  background: #eaf9ff;
  border-right: 1px solid #d3e2e8;
}

.demo-app-sidebar-section {
  padding: 2em;
}

.demo-app-main {
  flex-grow: 1;
  padding: 3em;
}

.fc { /* the calendar root */
  max-width: 1100px;
  margin: 0 auto;
}

</style>
