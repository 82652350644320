<template>
<div class="modal-card" style="width: auto">
  <header class="modal-card-head">
    <p class="modal-card-title">Detalle de cita</p>
    <button
        type="button"
        class="delete"
        @click="$parent.close()"/>
  </header>
  <section class="modal-card-body">
    <p><b>Fecha y hora de cita laboratorio: </b><span>{{event.date_time_formatted}}</span></p>
  <p><b>Tipo paciente: </b><span>{{event.patient_type_formatted}}</span></p>
    <p><b>No.solicitud: </b><span>{{event.folio}}</span></p>
    <p><b>Nombre completo: </b><span>{{event.full_name}}</span></p>
    <p><b>Observaciones: </b><span>{{event.observations}}</span></p>
    <p><b>Fecha de cita medica: </b><span>{{event.medical_date}}</span></p>
  </section>
  <footer class="modal-card-foot">
    <b-button native-type="button" type="is-primary"  @click="$parent.close()">Cerrar</b-button>
    <b-button native-type="button" type="is-danger" @click="onDelete()">Borrar cita</b-button>
    <b-button native-type="button" type="is-success" @click="$emit('edit'); $parent.close()">Editar cita</b-button>
  </footer>
</div>
</template>

<script>
import DataSchedule from "@/components/schedule/DataSchedule";

export default {
  name: "DetailsSchedule",
  components: {
    DataSchedule
  },
  props: {
    event: {}
  },
  methods: {
    onDelete() {
      this.loading = true;
      this.$buefy.dialog.confirm( {
        title: 'Borrar cita',
        message: '¿Estas seguro de borrar la cita?',
        confirmText: 'Si, borrar',
        cancelText: 'No, cancelar',
        onConfirm: () => {
          this.$loader.show();
          this.$http.delete(`/schedules/${this.event.id}`).then(() => {
            this.$toast.success('Cita borrada correctamente');
            this.$emit('update', new Date(this.event.date_time));
            this.$parent.close();
          }).finally(()=> {
            //this.$loader.hide();
          });
        }
      })
    },
  }
}
</script>

<style scoped>

</style>
